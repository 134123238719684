import { ApolloClient } from '@apollo/client';
import {
  V2Gateway_GetUserDocument,
  V2Gateway_GetUserQuery,
  V2Gateway_GetUserQueryResult,
} from 'Generated/graphql';
import { AppRoutePath } from 'Pages/routes';
import { AccountType } from './AccountType';

type user = NonNullable<V2Gateway_GetUserQueryResult['data']>['getUser']

export const getUserServerSide = async (
  client: ApolloClient<object>,
  cookie?: string,
): Promise<user | undefined> => {
  return client.query<V2Gateway_GetUserQuery | undefined>({
    fetchPolicy: 'no-cache',
    query: V2Gateway_GetUserDocument,
    context: cookie ? {
      headers: {
        cookie,
      },
    } : undefined,
  }).then((res) => {
    if (res?.error) {
      console.error(res.error);
    }

    if (res?.errors) {
      res.errors.forEach(e => {
        console.error(e);
      });
    }

    if (res?.data) {
      return res.data.getUser;
    }
  });
};

export const getDashboardPageForAccountType = (
  a: AccountType | undefined,
  currentPage: string | undefined,
) => {
  let destination = AppRoutePath.DASHBOARD;
  if (a === AccountType.Customer || a === AccountType._LegacyCustomer) {
    destination = AppRoutePath.HOME;
  }

  if (destination === currentPage) {
    return undefined;
  }

  return destination as string;
};

export const getDefaultPageForAccountType = (
  a: AccountType | undefined,
  currentPage: string | undefined,
) => {
  let destination = AppRoutePath.DASHBOARD;
  if (a === AccountType.Customer || a === AccountType._LegacyCustomer) {
    destination = AppRoutePath.BROWSE_SERVICE_TYPES;
  }

  if (destination === currentPage) {
    return undefined;
  }

  return destination as string;
};

const getPostAuthRedirect = async (
  client: ApolloClient<object>,
  currentPage?: string,
  cookie?: string,
): Promise<string | undefined> => {
  const user = await getUserServerSide(client, cookie);
  const destination =
    getDefaultPageForAccountType(user?.accountType, currentPage);

  return destination;
};

export default getPostAuthRedirect;


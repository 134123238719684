import { AppRoutePath } from 'Pages/routes';
import { AccountType } from './AccountType';
import { SearchParam } from './Search';
import { SSRPropsContext } from 'next-firebase-auth';
import { PreviewData } from 'next';
import { ParsedUrlQuery } from 'querystring';
import getIsLoggedInCookieFromContext from './getIsLoggedInCookieFromContext';
import getPostAuthRedirect from './getPostAuthRedirect';
import { ApolloClient, ApolloQueryResult } from '@apollo/client';

export const getPostSigninRedirectUrl = (accountType: AccountType) => {
  if (accountType === AccountType.Customer) {
    return AppRoutePath.BROWSE_SERVICE_TYPES;
  }

  return AppRoutePath.DASHBOARD;
};

type Router = {
  replace: (url: string) => void;
  hardReplace: (url: string) => void;
}

export const postSigninRedirect = (
  router: Router,
  returnToRaw: string | undefined,
  accountType: AccountType,
) => {
  if (returnToRaw) {
    const returnTo = decodeURIComponent(returnToRaw);
    router.hardReplace(returnTo);
  } else {
    const redirectUrl = getPostSigninRedirectUrl(accountType);
    router.replace(redirectUrl);
  }
};

export const getSigninErrorMessageFromErrorCode = (errorCode: string) => {
  const code = errorCode.toLowerCase();

  if (code.includes('wrong-password')) {
    const msg =
      'The email and password you entered don\'t match. Please try again or try logging in through Google.';
    return msg;
  }

  const isAccountInUseError = code
    .includes('account-exists-with-different-credential');
  if (isAccountInUseError) {
    const msg = 'This email is already used. Please enter password.';
    return msg;
  }

  if (code === 'auth/too-many-requests') {
    const msg = 'Too many incorrect attempts, please try again later.';
    return msg;
  }

  const msg = 'An error occurred! Please reach out to support@bookpeazy.com';
  return msg;
};

export const getReturnToUrlFromRawSearch = (
  urlSearchRaw: string | undefined,
) => {
  if (!urlSearchRaw) {
    return;
  }

  const params = urlSearchRaw.length > 0 ?
    new URLSearchParams(urlSearchRaw) :
    undefined;

  if (params) {
    const returnTo = params.get(SearchParam.RETURN_TO);

    return returnTo;
  }
};

export const getLoginPageServiceSidePropsFn = (
  apolloClient: ApolloClient<object>,
) => async (
  context: SSRPropsContext<ParsedUrlQuery, PreviewData>,
) => {
  const isLoggedInCookie = getIsLoggedInCookieFromContext(context);
  if (isLoggedInCookie) {
    const isAnon = context.user?.firebaseUser?.isAnonymous;
    if (isAnon || !context.user?.firebaseUser) {
      return {
        props: {}
      };
    }

    const thisPage = AppRoutePath.LOGIN;
    const splitUrl = context.resolvedUrl.split('?');
    const reqPage = splitUrl[0];
    const returnTo = getReturnToUrlFromRawSearch(splitUrl[1]);

    const destination = returnTo ||
        await getPostAuthRedirect(
          apolloClient,
          reqPage,
          context.req.headers.cookie,
        );

    if (destination && destination !== thisPage) {
      return {
        props: {},
        redirect: {
          destination,
        },
      };
    }
  }

  return {
    props: {},
  };
};

export const handleAccountMigrationIfNeeded = async (
  anonymousIdToken: string | undefined,
  ownerIdToken: string,
  migrateFn: (anonymousIdToken: string, ownerIdToken: string) => Promise<void>,
) => {
  if (anonymousIdToken) {
    await migrateFn(
      anonymousIdToken,
      ownerIdToken,
    );
  }
};

type res = { firstName: string, accountType: number };

export const onAppSignInSuccess = (
  token: string,
  signinWithIdToken: (
    idToken: string,
    shouldCreateIfNotExists: boolean,
    cb: (res: res) => void,
    errCb: () => void,
  ) => Promise<void>,
  shouldCreateIfNotExists: boolean,
  client: {
    resetStore: () => Promise<ApolloQueryResult<unknown>[] | null>,
  },
  onError: () => void,
  onSuccess: (u: res) => Promise<void>,
) => {
  signinWithIdToken(
    token, shouldCreateIfNotExists, res => {
      client.resetStore().finally(async () => {
        await onSuccess(res);
      });
    }, () => {
      onError();
    },
  );
};


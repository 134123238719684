import { Label, Text, Box } from 'theme-ui';
import Theme from 'Common/themePeazy.json';

type InputWithLabelProps = {
  label?: string;
  help?: string;
  touched: boolean;
  error: string | undefined;
  children?: React.ReactNode;
}

const InputWithLabel: React.FC<InputWithLabelProps> = ({
  label,
  help,
  children,
  touched,
  error,
}) => {
  const hintColor = Theme.colors.darkBlue;

  // TODO: make this a layout
  return (
    <Label> {label}
      <Box
        sx={{
          position: 'relative',
          mb: '1.5rem'
        }}
      >
        {children}
        <Text
          variant="plainText"
          sx={{
            color: touched ? 'red' : hintColor,
            position: 'absolute',
            fontSize: '0.875rem'
          }}
        >
          {(touched && error) && error}
          {!touched && help}
        </Text>
      </Box>
    </Label>
  );
};

export default InputWithLabel;

import { ThemeUIStyleObject } from 'theme-ui';

const styles: Record<string, ThemeUIStyleObject> = {
  googleBtn: {
    width: '184px',
    height: '42px',
    borderRadius: '2px',
    boxShadow: '0 3px 4px 0 rgba(0,0,0,.25)',
    cursor: 'pointer',
  },
  googleIconWrapper: {
    position: 'absolute',
    mt: '1px',
    ml: '1px',
    width: '40px',
    height: '40px',
    borderRadius: '2px',
    backgroundColor: '#fff',
  },
  googleIcon: {
    position: 'absolute',
    mt: '11px',
    ml: '11px',
    width: '18px',
    height: '18px',
  },
  btnText: {
    float: 'right',
    margin: '11px 11px 0 0',
    color: '#757575',
    fontSize: '14px',
    letterSpacing: '0.2px',
    fontFamily: '"Roboto"',
  },
  '&:hover': {
    boxShadow: '0 0 6px #4285f4',
  },
  '&:active': {
    background: '#1669F2',
  }
};

export default styles;



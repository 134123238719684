import {
  Text, Link as TULink, Flex, Box, Card,
} from 'theme-ui';
import Footer from 'Common/components/Footer';
import styles from 'Style/login';
import Link from 'next/link';

type LoginPageLayoutProps = {
  children: React.ReactNode;
  signupUrl: string;
  // TODO: Use a simpler component for nav so we don't need to dep inject
  navigation: React.ReactNode;
}

const LoginPageLayout: React.FC<LoginPageLayoutProps> = ({
  children,
  signupUrl,
  navigation,
}) => {
  return (
    <Flex sx={styles.container}>
      <Box sx={styles.headerAndFooterWrap}>
        {navigation}
      </Box>
      <Flex sx={styles.mainContentWrap}>
        <Card sx={{
          ...styles.signinCard,
          width: ['95%', '536px'],
        }}>
          <Flex sx={{
            ...styles.signinLayout,
            width: '390px',
            maxWidth: '100%',
            mx: 'auto',
            alignItems: 'stretch',
          }}>
            {children}
            <Flex sx={{
              justifyContent: 'start',
              mt: '1rem'
            }}>
              <Text variant="body3">
                Don&apos;t have a log in?{' '}
                <Link href={signupUrl}>
                  <TULink variant="base" as="span">
                  Sign up for free
                  </TULink>
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Card>
      </Flex>
      <Box sx={styles.headerAndFooterWrap}>
        <Footer />
      </Box>
    </Flex>
  );
};

export default LoginPageLayout;

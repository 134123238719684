import { Flex, Spinner } from 'theme-ui';

type CenteredSpinnerProps = {
  size?: number,
}

const CenteredSpinner: React.FC<CenteredSpinnerProps> = ({ size }) => (
  <Flex sx={{ justifyContent: 'center' }}>
    <Spinner size={size ?? 48} />
  </Flex>
);

export default CenteredSpinner;

import { Alert } from 'antd';
import {
  Text, Link as TULink, Box,
} from 'theme-ui';
import Link from 'next/link';

type LoginPageErrorMessageProps = {
  signupUrl: string;
}

const LoginPageErrorMessage: React.FC<LoginPageErrorMessageProps> = ({
  signupUrl,
}) => {
  return (
    <Alert
      closable
      type="error"
      message={(
        <Box sx={{ textAlign: 'center' }}>
          <Text>
            Sorry, looks like we don&apos;t have that email address
            associated with an existing account.&nbsp;
            <Link href={signupUrl}>
              <TULink as="span" variant="base">
                Create your free account now
              </TULink>
            </Link>
          </Text>
        </Box>
      )}
    />
  );
};

export default LoginPageErrorMessage;


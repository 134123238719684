import { Box, Text } from 'theme-ui';
import styles from './style';

type SigninWithGoogleProps = {
  onClick: () => void;
  copyPrefix?: string;
}

const SigninWithGoogle: React.FC<SigninWithGoogleProps> = ({
  onClick,
  copyPrefix = 'Sign in',
}) => {
  return (
    <Box
      sx={styles.googleBtn}
      onClick={onClick}
      data-testid="signinWithGoogle"
    >
      <Box sx={styles.googleIconWrapper}>
        <img
          sx={styles.googleIcon}
          src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
        />
      </Box>
      <Text as="p" sx={styles.btnText}>
        {copyPrefix} with Google
      </Text>
    </Box>
  );
};

export default SigninWithGoogle;

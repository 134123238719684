import React, { KeyboardEventHandler, useRef } from 'react';
import { Field, FieldProps, Form, Formik, FormikConfig, FormikValues } from 'formik';
import * as yup from 'yup';
import { Box, Button, Flex, Input, Link as TULink, Text } from 'theme-ui';
import MimoblTheme from 'Common/theme.json';
import CenteredSpinner from '../CenteredSpinner';
import {
  GoogleAuthProvider,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import Link from 'next/link';
import { AppRoutePath } from 'Pages/routes';
import SigninWithGoogle from '../SigninWithGoogle';
import InputWithLabel from '../InputWithLabel';

type LoginFormProps = {
  onFirebaseSignInSuccess: (token: string) => void
  onSignInFailure: (message: string) => void
  isLoading: boolean
  errorMessage?: string;
}

const LoginForm: React.FC<LoginFormProps> = ({
  onFirebaseSignInSuccess,
  onSignInFailure,
  isLoading,
  errorMessage,
}) => {
  const auth = getAuth();
  const passwordInputRef = useRef<HTMLInputElement>(null);

  const getInitialValue = () => ({
    email: '',
    password: ''
  });

  const validationSchema = yup.object().shape({
    email: yup.string()
      .email('Please enter valid email')
      .required('Please enter your email'),
    password: yup.string().min(6, 'Password must be at least 8 symbols')
      .required('Please enter your password',
      ),
  });

  const formikCfg: FormikConfig<
    { email: string, password: string }
  > = {
    initialValues: getInitialValue(),
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (
      values
    ) => {
      await signInWithEmailAndPassword(auth, values.email, values.password)
        .then(async (userCredential) => {
          const user = userCredential.user;
          const idToken = await user.getIdToken();
          onFirebaseSignInSuccess(idToken);
        })
        .catch((error) => {
          console.error(error);
          const errorCode = error.code;
          onSignInFailure(errorCode);
        });
    },
    validationSchema: validationSchema
  };

  const handleSignInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        const idToken = await user.getIdToken();
        onFirebaseSignInSuccess(idToken);
      }).catch((error) => {
        const errorCode = error.code;
        onSignInFailure(errorCode);
      });
  };

  const switchInputFocus: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      passwordInputRef.current?.focus();
    }
  };

  return (
    <Formik {...formikCfg}>
      {({ handleSubmit, isSubmitting }: FormikValues) => (
        <Flex sx={{
          flexDirection: 'column',
          gap: '1rem',
          my: '2rem'
        }}>
          <Form onSubmit={handleSubmit}>
            <Flex sx={{
              flexDirection: 'column',
              gap: '1.5rem',
            }}>
              <Box
                sx={{
                  opacity: isSubmitting ? 0.2 : 1,
                  pointerEvents: isSubmitting ? 'none' : 'unset',
                }}
              >
                {errorMessage && (
                  <Text variant="error">
                    {errorMessage}
                  </Text>
                )}
                <Box>
                  <Field name="email">
                    {({ field, meta }: FieldProps) => (
                      <InputWithLabel
                        touched={meta.touched}
                        error={meta.error}
                      >
                        <Input
                          data-testid="loginForm_emailInput"
                          data-cy="loginForm_emailInput"
                          {...field}
                          placeholder="Email"
                          type="email"
                          onKeyDown={switchInputFocus}
                          name="email"
                        />
                      </InputWithLabel>
                    )}
                  </Field>
                </Box>
                <Field name="password">
                  {({ field, meta }: FieldProps) => (
                    <InputWithLabel
                      touched={meta.touched}
                      error={meta.error}
                    >
                      <Input
                        data-testid="loginForm_passwordInput"
                        data-cy="loginForm_passwordInput"
                        {...field}
                        placeholder="Password"
                        type="password"
                        ref={passwordInputRef}
                      />
                    </InputWithLabel>
                  )}
                </Field>
                <Link href={AppRoutePath.FORGOT_PASSWORD}>
                  <TULink
                    as="span"
                    variant="base"
                    sx={{ fontSize: '0.8rem' }}
                  >
                    Forgot password?
                  </TULink>
                </Link>
              </Box>
              <Flex sx={{
                flexDirection: 'column',
                gap: '0.5rem',
                alignItems: 'center',
              }}>
                <Button
                  data-testid="loginForm_submit"
                  data-cy="loginForm_submit"
                  type="submit"
                  variant="cardButton"
                  disabled={isSubmitting || isLoading}
                  sx={{
                    color: MimoblTheme.colors.black,
                    width: '100%',
                    mb: '1rem',
                  }}
                >{isSubmitting || isLoading ?
                    <CenteredSpinner size={24} /> :
                    'Log In'}
                </Button>
                <SigninWithGoogle
                  copyPrefix="Log In"
                  onClick={() => handleSignInWithGoogle()}
                />
              </Flex>
            </Flex>
          </Form>
        </Flex >
      )}
    </Formik >
  );
};

export default LoginForm;

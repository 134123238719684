import { ThemeUIStyleObject } from 'theme-ui';
import Theme from 'Common/themePeazy.json';

const styles: Record<string, ThemeUIStyleObject> = {
  container: {
    minWidth: '100vw',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  headerAndFooterWrap: { m: 0, zIndex: '2' },
  mainContentWrap: {
    boxSizing: 'border-box',
    background: Theme.colors.lightBlue,
    zIndex: '0',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    flex: '1 1 0'
  },
  signinCard: {
    zIndex: '2',
    width: ['95%', '70%', '536px'],
    mt: '3rem',
    mb: '10rem',
    borderRadius: '4px'
  },
  signinLayout: {
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    zIndex: '2',
  },
  formCard: {
    width: '60%',
    minWidth: 250,
    padding: '2rem 0',
    textAlign: 'center',
    mb: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  nonLoginActionsWrap: {
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#888888',
  },
  heroFigure: {
    display: 'none'
  },
  figureLightGreen: {
    display: 'none'
  },
};

export default styles;


import { useState, useEffect, useMemo } from 'react';
import { message } from 'antd';
import { Heading } from 'theme-ui';
import { AppRoutePath } from 'Pages/routes';
import { useRouter } from 'next/router';
import { withUserTokenSSR } from 'next-firebase-auth';
import { SearchParam } from 'Common/functions/Search';
import { getAnalytics, logEvent } from 'firebase/analytics';
import LoginForm from 'Common/components/LoginForm';
import { useApolloClient } from '@apollo/client';
import {
  getSigninErrorMessageFromErrorCode,
  getLoginPageServiceSidePropsFn,
  onAppSignInSuccess,
  handleAccountMigrationIfNeeded,
  postSigninRedirect,
} from 'Common/functions/LoginPage';
import LoginPageErrorMessage from
  'Common/components/LoginPage/LoginPageErrorMessage';
import LoginPageLayout from 'Common/components/LoginPage/LoginPageLayout';
import { NextPage } from 'next';
import { AppApolloClient } from './_app';
import Navigation, { NavigationContentType } from 'Common/components/Navigation';
import {
  useV2Gateway_MigrateAnonymousIdentityMutation,
} from 'Generated/graphql';
import signinWithIdToken from 'Common/functions/signinWithIdToken';
import useIsAnonymous from 'Common/hooks/useIsAnonymous';

const Login: NextPage = () => {
  const router = useRouter();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [_key, _setKey] = useState(0);
  const { query } = router;
  const returnTo = query[SearchParam.RETURN_TO] as string | undefined;
  const urlParams = (new URLSearchParams(
    query as Record<string, string>
  )).toString();
  const urlParamsRaw = urlParams ? `?${urlParams}` : '';
  const signupUrl = `${AppRoutePath.CUSTOMER_SIGNUP}${urlParamsRaw}`;
  const client = useApolloClient();
  const [migrateAnonymousAccount] =
    useV2Gateway_MigrateAnonymousIdentityMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const isAnonymous = useIsAnonymous();

  const postSignupMergeAccountIdToken = useMemo(() => {
    const token =
      query[SearchParam.POST_SIGNUP_MIGRATE_ANONYMOUS_ACCOUNT_ID_TOKEN];
    return token ? String(token) : undefined;
  }, [query]);

  const doMigrateAnonymousAccount = async (
    anonymousIdToken: string,
    ownerIdToken: string,
  ) => {
    await migrateAnonymousAccount({
      variables: {
        anonymousIdToken,
        ownerIdToken,
      }
    });
  };

  // If the user is getting redirected somewhere after login, auto create their
  // account on login attempt if they don't have one
  const shouldCreateIfNotExists = useMemo(
    () => Boolean(returnTo),
    [returnTo],
  );

  useEffect(() => {
    logEvent(
      getAnalytics(),
      'navigate_login_page',
    );
  }, []);

  const handleSigninError = (errorCode: string) => {
    if (errorCode.toLowerCase().includes('user-not-found')) {
      setError(true);
      _setKey(_key + 1);
      return;
    }

    const msg = getSigninErrorMessageFromErrorCode(errorCode);
    setErrorMessage(msg);
  };

  return (
    <LoginPageLayout
      signupUrl={signupUrl}
      navigation={(
        <Navigation
          navContentType={isAnonymous ?
            NavigationContentType.ANONYMOUS_CUSTOMER :
            NavigationContentType.CUSTOMER}
          hideBackButton
          signupButtonLink={AppRoutePath.CUSTOMER_SIGNUP}
        />
      )}
    >
      <Heading variant='concierge.h1'>
        Log In
      </Heading>
      {error && (
        <LoginPageErrorMessage signupUrl={signupUrl} />
      )}
      <LoginForm
        errorMessage={errorMessage}
        onFirebaseSignInSuccess={token => {
          setIsLoading(true);
          onAppSignInSuccess(
            token,
            signinWithIdToken,
            shouldCreateIfNotExists,
            client,
            () => {
              setIsLoading(false);
              setError(true);
              _setKey(_key + 1);
            },
            async (res) => {
              message.success(`Welcome, ${res.firstName}! 👋`);

              await handleAccountMigrationIfNeeded(
                postSignupMergeAccountIdToken,
                token,
                doMigrateAnonymousAccount,
              );

              postSigninRedirect(
                {
                  replace: router.replace,
                  hardReplace: url => window.location.replace(url)
                },
                returnTo,
                res.accountType,
              );

              setTimeout(() => setIsLoading(false), 3000);
            },
          );
        }}
        onSignInFailure={handleSigninError}
        isLoading={isLoading}
      />
    </LoginPageLayout>
  );
};

export const getServerSideProps =
  withUserTokenSSR()(getLoginPageServiceSidePropsFn(AppApolloClient));

export default Login;
